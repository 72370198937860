<template>
	<Card title="Create New Password">
		<Form>
			<p>Your new password must be different from previously used passwords.</p>
			<Input
				id="password"
				label="New Password"
				hasIcon="true"
				iconType="password"
				type="password"
				placeholder="New Password"
				autoComplete="new-password"
				class="last"
				vid="confirmation"
				rules="required|verify_password"
			/>
			<Input
				id="password confirm"
				label="Confirm Password"
				hasIcon="true"
				iconType="password"
				type="password"
				placeholder="Confirm Password"
				rules="required|confirmed:confirmation"
			/>
			<SubmitButton class="large-spacer center-spacing">Continue</SubmitButton>
		</Form>
	</Card>
</template>

<script>
	import Card from "@/components/Card";
	import Form from "@/components/Form";
	import Input from "@/components/Input";
	import SubmitButton from "@/components/SubmitButton";
	export default {
		components: {
			Card,
			Form,
			Input,
			SubmitButton,
		},
	};
</script>

<style lang="scss" scoped>
	form {
		p {
			max-width: 350px;
			text-align: center;
			padding: 1rem 0 2.5rem;
		}
	}
</style>
